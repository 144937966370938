import React, { Fragment, useState } from 'react';
import { Link } from 'gatsby';
import slugify from 'slugify';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { FaBars } from 'react-icons/fa';

import { StyledNav, StyledMenu, ListItem, ParentItem, StyledDrawer, StyledDrawerMenu, MenuButton } from './styles/nav';

const MainMenu = ({ menuItems, path }) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const handleClick = e => setAnchorEl(e.currentTarget);
	const handleClose = () => setAnchorEl(null);

	return (
		<StyledNav>
			<StyledMenu>
				{menuItems.map(menuItem => (
					<Fragment key={menuItem.wordpress_id}>
						<ListItem
							key={menuItem.wordpress_id}
							isCurrent={path.pathname === menuItem.url.replace(/https?:\/\/nessatoye.com/, '')}>
							{!menuItem.child_items ? (
								<Link to={menuItem.url.replace(/https?:\/\/nessatoye.com/, '')}>{menuItem.title}</Link>
							) : (
								<ParentItem
									aria-controls={`${slugify(menuItem.title)}--sub-menu`}
									aria-haspopup='true'
									onClick={handleClick}>
									{menuItem.title}
								</ParentItem>
							)}
						</ListItem>

						{menuItem.child_items ? (
							<Menu
								id={`${slugify(menuItem.title)}--sub-menu`}
								anchorEl={anchorEl}
								keepMounted
								open={Boolean(anchorEl)}
								onClose={handleClose}>
								{menuItem.child_items.map(item => (
									<MenuItem key={item.wordpress_id} onClick={handleClose}>
										<Link to={item.url.replace(/https?:\/\/nessatoye.com\/illustration_type/, '/illustrations')}>
											{item.title}
										</Link>
									</MenuItem>
								))}
							</Menu>
						) : (
							''
						)}
					</Fragment>
				))}
			</StyledMenu>
		</StyledNav>
	);
};

const MainMenuMobile = ({ menuItems, path }) => {
	const [state, setState] = React.useState({
		top: false,
		left: false,
		bottom: false,
		right: false,
	});
	const toggleDrawer = (side, open) => event => {
		if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return;
		}

		setState({ ...state, [side]: open });
	};
	return (
		<>
			<MenuButton onClick={toggleDrawer('right', true)}>
				<FaBars />
			</MenuButton>
			<StyledDrawer
				anchor='right'
				open={state.right}
				onOpen={toggleDrawer('right', true)}
				onClose={toggleDrawer('right', false)}>
				<StyledDrawerMenu>
					{menuItems.map(menuItem => (
						<Fragment key={menuItem.wordpress_id}>
							<ListItem
								key={menuItem.wordpress_id}
								isCurrent={path.pathname === menuItem.url.replace(/https?:\/\/nessatoye.com/, '')}
								onClick={toggleDrawer('right', false)}>
								{!menuItem.child_items ? (
									<Link to={menuItem.url.replace(/https?:\/\/nessatoye.com/, '')}>{menuItem.title}</Link>
								) : (
									<ParentItem aria-controls={`${slugify(menuItem.title)}--sub-menu`} aria-haspopup='true'>
										{menuItem.title}
									</ParentItem>
								)}
							</ListItem>

							{menuItem.child_items ? (
								<Menu id={`${slugify(menuItem.title)}--sub-menu`} keepMounted>
									{menuItem.child_items.map(item => (
										<MenuItem key={item.wordpress_id}>
											<Link to={item.url.replace(/https?:\/\/nessatoye.com\/illustration_type/, '/illustrations')}>
												{item.title}
											</Link>
										</MenuItem>
									))}
								</Menu>
							) : (
								''
							)}
						</Fragment>
					))}
				</StyledDrawerMenu>
			</StyledDrawer>
		</>
	);
};

export { MainMenu, MainMenuMobile };
