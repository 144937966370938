/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import Header from './header';
import Footer from './footer';
import './layout.css';
import { Container, Main } from './styles/layout';

const Layout = ({ children, location }) => {
	const data = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					title
					description
				}
			}
		}
	`);
	return (
		<Container>
			<Header siteTitle={data.site.siteMetadata.title} path={location} />
			<Main>{children}</Main>
			<Footer />
		</Container>
	);
};

Layout.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Layout;
