import React from 'react';
// import { useStaticQuery, graphql } from 'gatsby';

import { LogoTextContainer, LogoText, LogoSubText } from './styles/header';

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const Logo = () => {
	// const data = useStaticQuery(graphql`
	// 	query {
	// 		allWordpressAcfOptions {
	// 			edges {
	// 				node {
	// 					id
	// 					options {
	// 						logo {
	// 							# main_logo {
	// 							# 	localFile {
	// 							# 		childImageSharp {
	// 							# 			fluid(maxWidth: 600) {
	// 							# 				...GatsbyImageSharpFluid_tracedSVG
	// 							# 			}
	// 							# 		}
	// 							# 	}
	// 							# }
	// 							# seasonal_ornment_left {
	// 							# 	localFile {
	// 							# 		childImageSharp {
	// 							# 			fixed(width: 300) {
	// 							# 				...GatsbyImageSharpFixed_withWebp_tracedSVG
	// 							# 			}
	// 							# 		}
	// 							# 	}
	// 							# }
	// 							# seasonal_ornment_right {
	// 							# 	localFile {
	// 							# 		childImageSharp {
	// 							# 			fixed(width: 300) {
	// 							# 				...GatsbyImageSharpFixed_withWebp_tracedSVG
	// 							# 			}
	// 							# 		}
	// 							# 	}
	// 							# }
	// 						}
	// 					}
	// 				}
	// 			}
	// 		}
	// 	}
	// `);

	// const {
	// 	// main_logo,
	// 	seasonal_ornment_left,
	// 	seasonal_ornment_right,
	// } = data.allWordpressAcfOptions.edges[0].node.options.logo;
	return (
		<>
			{/* <LogoOrnament imgSrc={seasonal_ornment_left.localFile.childImageSharp.fixed.src} small={true} /> */}
			<LogoTextContainer>
				<LogoText>Vanessa Toye</LogoText>
				<LogoSubText>Illustration</LogoSubText>
			</LogoTextContainer>
			{/*<Img fluid={main_logo.localFile.childImageSharp.fluid} />*/}
			{/* <LogoOrnament imgSrc={seasonal_ornment_right.localFile.childImageSharp.fixed.src} /> */}
		</>
	);
};

export default Logo;
