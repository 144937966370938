import styled, { css } from 'styled-components';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';

const StyledNav = styled.nav`
	li {
		list-style: none;
		margin: 0 0.5rem;
	}
	a,
	button {
		color: #444;
		font-family: 'Nunito', sans-serif;
		text-decoration: none;
		font-size: 1.25rem;
		padding: 0.25rem 0.5rem;
		transition: all 0.2s ease-in-out;

		&:hover {
			color: var(--primary-color);
		}
	}
`;

const StyledMenu = styled.ul`
	display: flex;
	justify-content: center;
	margin: 1rem 0 3rem;
	padding: 0;
`;
const IconMenu = styled(StyledMenu)`
	display: flex;
	justify-content: center;
	margin-bottom: 2rem;

	& > li {
		a {
			font-size: 1.5rem;
			color: var(--primary-color);
			transition: color 0.2s;
		}
		&:hover {
			a {
				color: var(--primary-color-lighten);
			}
		}
	}
`;

const ListItem = styled.li`
	${props =>
		props.isCurrent &&
		css`
			& > a {
				color: var(--primary-color);
			}
		`};
`;

const ParentItem = styled.button`
	-webkit-appearance: none;
	-moz-appearance: none;
	background: none;
	border: none;
	margin-top: -0.25rem;
	cursor: pointer;
`;

const MenuButton = styled.button`
	-webkit-appearance: none;
	-moz-appearance: none;
	background: none;
	border: none;
`;

const StyledDrawer = styled(SwipeableDrawer)`
	li {
		list-style: none;
		margin: 0 0.5rem;
	}
	a,
	button {
		display: block;
		color: #444;
		font-family: 'Nunito', sans-serif;
		text-decoration: none;
		font-size: 1.25rem;
		padding: 0.5rem 0.5rem;
		transition: all 0.2s ease-in-out;

		&:hover {
			color: var(--primary-color);
		}
	}
`;

const StyledDrawerMenu = styled.ul`
	width: 50vw;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0;
	padding-top: 2rem;
	list-style-type: none;

	/* & a {
		text-decoration: none;
		color: #444;
	} */
`;

export { StyledNav, StyledMenu, IconMenu, ListItem, ParentItem, MenuButton, StyledDrawer, StyledDrawerMenu };
