import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Hidden from '@material-ui/core/Hidden';

import { MainMenu, MainMenuMobile } from './menus';

const Nav = ({ menu, path }) => {
	const data = useStaticQuery(graphql`
		query {
			wordpressMenusMenusItems(wordpress_id: { eq: 4 }) {
				items {
					wordpress_id
					url
					title
					menu_order
					# child_items {
					# 	title
					# 	menu_order
					# 	menu_item_parent
					# 	url
					# 	wordpress_id
					# }
				}
			}
		}
	`);

	return (
		<>
			<Hidden smUp>
				<MainMenuMobile menuItems={data.wordpressMenusMenusItems.items} path={path} />
			</Hidden>
			<Hidden smDown>
				<MainMenu menuItems={data.wordpressMenusMenusItems.items} path={path} />
			</Hidden>
		</>
	);
};
Nav.propTypes = {
	menu: PropTypes.array,
};

Nav.defaultProps = {
	menu: [],
};

export default Nav;
