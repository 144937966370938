import styled, { css } from 'styled-components';
import { Link } from 'gatsby';

const TotoHeader = styled.header`
	background: #fff;
	/* height: calc(100vh - 4rem); */
	padding: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: relative;
`;

const LogoContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	@media screen and (min-width: 48em) {
		width: 45rem;
		margin: 3rem auto 1rem;
	}

	/* margin-top: -5vh; */
`;
const LogoOrnament = styled.span`
	display: none;
	@media screen and (min-width: 47.5em) {
		background: url(${props => props.imgSrc});
		background-size: contain;
		background-repeat: no-repeat;
		display: block;
		width: 150px;
		margin: -2.5rem 1rem;
		${props =>
			props.small &&
			css`
				width: 120px;
				margin-top: -1.125rem;
			`}
	}
`;

const LogoTextContainer = styled.span`
	display: flex;
	flex-direction: column;
	align-items: center;
`;
const LogoText = styled.span`
	font-family: 'GGCC';
	font-size: 4rem;
	font-weight: 500;
	@media screen and (min-width: 47.5em) {
		font-size: 5.5rem;
	}
`;
const LogoSubText = styled.span`
	font-family: 'GGB';
	margin-top: -0.75rem;
	font-size: 2.125rem;
	font-weight: 500;
	@media screen and (min-width: 47.5em) {
		font-size: 3rem;
	}
`;

const LogoLink = styled(Link)`
	display: flex;
	text-decoration: none;
	color: #444;
`;

export { TotoHeader, LogoContainer, LogoOrnament, LogoTextContainer, LogoText, LogoSubText, LogoLink };
