// import { Link } from 'gatsby';
import PropTypes from "prop-types"
import React from "react"

import { FaInstagram, FaPinterest, FaLinkedin } from "react-icons/fa"

import { StyledNav, IconMenu, ListItem } from "./styles/nav"

const IconNav = ({ menu }) => (
  <StyledNav>
    <IconMenu>
      {menu.map(menuItem => (
        <ListItem key={menuItem.wordpress_id}>
          <a href={menuItem.url} rel="noopener noreferrer" target="_blank">
            {menuItem.title === "Instagram" ? (
              <FaInstagram />
            ) : menuItem.title === "LinkedIn" ? (
              <FaLinkedin />
            ) : (
              <FaPinterest />
            )}
          </a>
        </ListItem>
      ))}
    </IconMenu>
  </StyledNav>
)

IconNav.propTypes = {
  menu: PropTypes.array,
}

IconNav.defaultProps = {
  menu: [],
}

export default IconNav
