import styled, { css } from 'styled-components';
import Masonry from 'react-masonry-css';
import Img from 'gatsby-image';
import LightboxGallery from '../LightboxGallery';

const Container = styled.div`
	background-color: #fff;
	background-image: radial-gradient(var(--inative-color) 15%, transparent 16%),
		radial-gradient(var(--inative-color) 15%, transparent 16%);
	background-size: 30px 30px;
	background-position: 0 0, 15px 15px;
	/* display: grid;
  grid-template-columns: 280px 1fr;
  height: 100vh; */
	padding: 2rem;
	overflow: hidden;
`;
const Main = styled.main`
	max-width: 60rem;
	min-height: calc(100vh - 10rem);
	background: #fff;
	margin: 0 auto;
	padding: 1rem 0 2rem;
	overflow: hidden;
	// border-top: 1px solid var(--primary-color);

	/* & img {
		transition: all 0.2s;
		&:hover {
			opacity: 0.8;
			transform: scale(1.05);
		}
		/* pointer-events: none; */
	} */
`;

const Grid = styled.div`
	display: grid;
	@media screen and (min-width: 37.5rem) {
		${props =>
			props.rows &&
			css`
				grid-template-rows: ${props.rows.join(' ')};
				grid-row-gap: ${props.rowGap || `0px`};
			`}
		${props =>
			props.columns &&
			css`
				grid-template-columns: ${props.columns.join(' ')};
				grid-column-gap: ${props.columnGap || `0px`};
			`}
	}
`;

const BlogGrid = styled.div`
	/* display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  grid-column-gap: var(--gap); */
`;

const CardsGrid = styled(Masonry)`
	display: flex;
	margin-left: calc(var(--gap) * -1); /* gutter size offset */
	width: auto;
	& > .my-masonry-grid_column {
		padding-left: var(--gap); /* gutter size */
		background-clip: padding-box;
	}
`;

const ProtectedLightboxGallery = styled(LightboxGallery)`
	& img {
		pointer-events: none;
	}
`;

const PageTitle = styled.h1`
	${props =>
		props.isHidden &&
		css`
			position: relative !important;
			height: 1px;
			width: 1px;
			overflow: hidden;
			clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
			clip: rect(1px, 1px, 1px, 1px);
			white-space: nowrap; /* added line */
			margin-bottom: 4rem;
		`}
`;

const PageContent = styled.div`
	& .is-resized {
		display: flex;
		flex-direction: row-reverse;
		justify-content: flex-end;
		& a {
			display: block;
			width: 8rem;
			margin-top: -5px;
			margin-left: 10px;
		}
	}
`;

const ClippedImg = styled(Img)`
	clip-path: ellipse(45% 50% at 50% 50%);
	shape-outside: ellipse(45% 50% at 50% 50%);
`;

const Sidebar = styled.aside`
	display: none;
	@media screen and (min-width: 37.5rem) {
		display: block;
		position: sticky;
		top: 0;
		z-index: 10;
	}
`;

const PostUl = styled.ul`
	padding: 0;
	margin: 0;
`;

const PostLi = styled.li`
	list-style-type: none;
	& > a {
		text-decoration: none;
	}

	h4 {
		margin: 0;
	}
	time {
		font-size: 0.75rem;
		color: #999;
	}
`;

export {
	Container,
	Main,
	Grid,
	BlogGrid,
	CardsGrid,
	ProtectedLightboxGallery,
	PageTitle,
	PageContent,
	ClippedImg,
	Sidebar,
	PostUl,
	PostLi,
};
