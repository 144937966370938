import React, { useState, useCallback } from 'react';
// import Gallery from 'react-photo-gallery';
import Carousel, { Modal, ModalGateway } from 'react-images';
import PropTypes from 'prop-types';
import Gallery from './PhotoGallery/Gallery';

const LightboxGallery = ({ data }) => {
	const images = data.map(({ node }) => ({
		src: node.featured_media.localFile.childImageSharp.fluid.src,
		srcSet: node.featured_media.localFile.childImageSharp.fluid.srcSet,
		width: node.featured_media.localFile.childImageSharp.sizes.presentationWidth,
		height: node.featured_media.localFile.childImageSharp.sizes.presentationHeight,
		title: node.title,
	}));

	const gImgs = data.map(({ node }) => node.featured_media.localFile.childImageSharp.fluid);

	const [currentImage, setCurrentImage] = useState(0);
	const [viewerIsOpen, setViewerIsOpen] = useState(false);

	const openLightbox = useCallback((event, { photo, index }) => {
		setCurrentImage(index);
		setViewerIsOpen(true);
	}, []);

	const closeLightbox = () => {
		setCurrentImage(0);
		setViewerIsOpen(false);
	};

	return (
		<>
			<Gallery photos={images} gImgs={gImgs} direction={'column'} onClick={openLightbox} />
			<ModalGateway>
				{viewerIsOpen ? (
					<Modal onClose={closeLightbox}>
						<Carousel
							currentIndex={currentImage}
							views={images.map(x => ({
								...x,
								srcset: x.srcSet,
								caption: x.title,
							}))}
						/>
					</Modal>
				) : null}
			</ModalGateway>
		</>
	);
};

LightboxGallery.propTypes = {
	data: PropTypes.array.isRequired,
};

export default LightboxGallery;
