import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Img from 'gatsby-image';

const imgWithClick = { cursor: 'pointer' };

const Photo = ({ index, onClick, photo, margin, direction, top, left, width, height, key, gImg }) => {
	const Thumb = styled(Img)`
		margin: ${margin}px;
		display: block;
		width: ${direction === 'equal' ? '100%' : `${width}px`};
		height: ${direction === 'equal' ? '100%' : `${height}px`};
		${direction === 'column' &&
			css`
				position: absolute !important;
				left: ${left}px;
				top: ${top}px;
			`}
		& img {
			cursor: pointer;
			transition: all 0.2s ease-in-out !important;
			&:hover {
				transform: scale(1.025);
			}
		}
	`;

	const imgStyle = {
		/*margin: margin, display: 'block' */
	};
	if (direction === 'column') {
		// imgStyle.position = 'absolute';
		// imgStyle.left = left;
		// imgStyle.top = top;
	}

	const handleClick = event => {
		onClick(event, { photo, index });
	};

	return gImg ? (
		<Thumb key={key} fluid={gImg} onClick={onClick ? handleClick : null} />
	) : (
		<img
			key={key}
			style={onClick ? { ...imgStyle, ...imgWithClick } : imgStyle}
			{...photo}
			onClick={onClick ? handleClick : null}
			alt=''
		/>
	);
};

export const photoPropType = PropTypes.shape({
	key: PropTypes.string,
	src: PropTypes.string.isRequired,
	width: PropTypes.number.isRequired,
	height: PropTypes.number.isRequired,
	alt: PropTypes.string,
	title: PropTypes.string,
	srcSet: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
	sizes: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
});

Photo.propTypes = {
	index: PropTypes.number.isRequired,
	onClick: PropTypes.func,
	photo: photoPropType.isRequired,
	margin: PropTypes.number,
	top: props => {
		if (props.direction === 'column' && typeof props.top !== 'number') {
			return new Error('top is a required number when direction is set to `column`');
		}
	},
	left: props => {
		if (props.direction === 'column' && typeof props.left !== 'number') {
			return new Error('left is a required number when direction is set to `column`');
		}
	},
	direction: PropTypes.string,
};

export default Photo;
