import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"

import IconNav from "./iconNav"

import { FooterContainer } from "./styles/footer.js"

const Footer = ({ menu }) => {
  const data = useStaticQuery(graphql`
    {
      wordpressMenusMenusItems(wordpress_id: { eq: 5 }) {
        items {
          wordpress_id
          url
          title
          menu_order
        }
      }
    }
  `)
  return (
    <FooterContainer>
      <IconNav menu={data.wordpressMenusMenusItems.items} />
      <p>
        All Images and Content ©{new Date().getFullYear()} Vanessa Toye. All
        rights reserved.
      </p>
    </FooterContainer>
  )
}

Footer.propTypes = {
  menu: PropTypes.array,
}

Footer.defaultProps = {
  menu: [],
}

export default Footer
