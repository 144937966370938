import React from 'react';
import PropTypes from 'prop-types';

import Logo from './logo';
import Nav from './nav';
// import IconNav from "./iconNav"

import { TotoHeader, LogoContainer, LogoLink } from './styles/header';

const Header = ({ siteTitle, menu, path }) => (
	<TotoHeader>
		<LogoContainer>
			<h1>
				<LogoLink to='/'>
					<Logo />
				</LogoLink>
			</h1>
		</LogoContainer>
		<Nav path={path} />
	</TotoHeader>
);

Header.propTypes = {
	siteTitle: PropTypes.string,
	menu: PropTypes.array,
	path: PropTypes.object,
};

Header.defaultProps = {
	siteTitle: ``,
	menu: [],
	path: {},
};

export default Header;
