import styled from "styled-components"

const FooterContainer = styled.footer`
  max-width: 60rem;
  margin: 1rem auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export { FooterContainer }
